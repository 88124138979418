<template>
  <div class="dashboard-org innerpage">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col cols="12">
          <div v-if="currentUser" class="d-lg-flex align-center">
            <h1 class="text-h2 mr-3">Org admin dashboard</h1>
            <v-chip
              class="ml-2 mt-2 mb-2"
              color="cora-chip-color-green"
              text-color="white"
            >
              <v-avatar left class="cora-chip-avatar-color-green darken-4">
                {{ itemsTotal }}
              </v-avatar>
              Submitted reports
            </v-chip>
          </div>

          <v-divider class="mt-8 mb-3"></v-divider>
        </v-col>
      </v-row>
      <!-- <v-alert
        border="left"
        color="#34a6c6"
        type="info"
        icon="mdi-alert-circle"
        class="mt-5"
        max-width="500px"
        style="font-size: 14px"
      >
        Reports can only be merged into one consolidated report when these
        conditions are met:
        <ul>
          <li>Each report's status is "Approved"</li>
          <li>Each report has the same location</li>
          <li>Each report is with the same organization</li>
        </ul>
      </v-alert> -->

      <!-- <v-row>
        <v-col cols="12">
          <v-card-title class="px-0">
            <h2 class="text-h3">
              {{ itemsConsolidatedTotal }} Consolidated reports
            </h2>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-data-table
            :loading="dataConsolidateLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="headers_consolidate"
            :items="consReports"
            :options.sync="options_cons"
            :server-items-length="itemsConsolidatedTotal"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-5 mb-15 align-center"
            :search="search"
            show-expand
            :custom-filter="customDataTableItemsFilter"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
          >
            <template slot="no-data">
              <b>You have no Consolidated Reports..</b><br />
              Select which reports you would like to merge and click the
              Consolidate Reports button.
            </template>

            <template v-slot:item.risk="{ item }">
              <v-chip :color="getColor(item.final_risk_level)" dark>{{
                item.final_risk_level
              }}</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="item.html"
                    target="_blank"
                  >
                    <v-icon class="mr-2">mdi-file-document-outline</v-icon>
                  </a>
                </template>
                <span>View report</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="item.pdf"
                    target="_blank"
                  >
                    <v-icon class="mr-2">mdi-download</v-icon>
                  </a>
                </template>
                <span>Download PDF report</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="consolidateFireModalDeleteReport(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete report</span>
              </v-tooltip>
            </template>

            <template #item.id_label="{ item }">
              {{ item.id }} - {{ item.label }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-0 ma-0" :colspan="headers.length">
                <v-card class="fix-detail-dashboard  rounded-0">
                  <v-card-text>
                    <p>
                      <b>Original Report IDs:</b>
                      {{ item.original_report_ids.replace(/[\[\]']+/g, "") }}
                    </p>
                    <p><b>Consolidated Report Name:</b> {{ item.label }}</p>
                    <p><b>Organization Name:</b> {{ item.user.name }}</p>
                    <p><b>Workergroups:</b></p>
                    <ul class="ml-4">
                      <li
                        v-for="(wk, i) in Object.values(item.original_reports)"
                        v-bind:key="i"
                      >
                        <p>
                          {{ getNestedObject(wk, ["workergroup", "id"]) }} -
                          {{ getNestedObject(wk, ["workergroup", "name"]) }}
                        </p>
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="12">
          <v-card-title class="px-0">
            <v-container class="fluid d-lg-flex align-center">
              <h2 class="text-h3 mr-auto">
                {{ itemsTotal }} Reports submitted
              </h2>
              <!-- <v-btn
                rounded
                color="primary"
                :disabled="showConsolidateButton"
                @click="modalConsolidateReports()"
              >
                CONSOLIDATE REPORTS
              </v-btn> -->
            </v-container>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-data-table
            v-model="selectedReports"
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="headers"
            :items="orgReports"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :sort-desc="[false, true]"
            multi-sort
            show-expand
            show-select
            class="elevation-5 mb-15 align-center"
            :search="search"
            :custom-filter="customDataTableItemsFilter"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
          >
            <template v-slot:item.risk="{ item }">
              <v-chip :color="getColor(item.final_risk_level)" dark>Low</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip
                v-if="
                  item.status != 'In Progress' &&
                    item.status != 'started' &&
                    item.status != 'Incomplete' &&
                    item.active_user_id == null
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="`/report/${item.id}`"
                  >
                    <v-icon class="mr-2">mdi-playlist-edit</v-icon>
                  </a>
                </template>
                <span>Edit report</span>
              </v-tooltip>

              <v-tooltip
                v-if="
                  item.status != 'In Progress' &&
                    item.status != 'started' &&
                    item.status != 'Incomplete' &&
                    item.active_user_id == null
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="`/quizreport/${item.id}`"
                    target="_blank"
                  >
                    <v-icon class="mr-2">mdi-file-document</v-icon>
                  </a>
                </template>
                <span>View report</span>
              </v-tooltip>

              <v-tooltip bottom v-if="item.active_user_id == null">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="fireModalDeleteReport(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete report</span>
              </v-tooltip>

              <v-tooltip v-if="item.active_user_id != null" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ff0000" v-bind="attrs" v-on="on" class="mr-2">
                    mdi-access-point</v-icon
                  >
                </template>
                <span>On live editing</span>
              </v-tooltip>
            </template>

            <template #item.id_title="{ item }">
              {{ item.id }} -
              {{ getNestedObject(item, ["courseDetails", 0, "category"]) }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-0 ma-0 " :colspan="headers.length">
                <v-card class="fix-detail-dashboard  rounded-0">
                  <v-card-text>
                    <p><b>Report ID:</b> {{ item.id }}</p>
                    <p>
                      <b>Report name:</b>
                      {{ getNestedObject(item, ["courseDetails", 0, "title"]) }}
                    </p>
                    <p>
                      <b>Report category:</b>
                      {{
                        getNestedObject(item, ["courseDetails", 0, "category"])
                      }}
                    </p>
                    <p><b>Submitted by:</b> {{ item.user.name }}</p>
                    <p>
                      <b>Review status:</b> (you can't change this if the report
                      is not in "Submitted" status )
                    </p>
                    <v-select
                      v-model="item.status"
                      :items="['Approved', 'Rejected', 'Reviewing']"
                      label="No status"
                      dense
                      style="max-width: 300px"
                      solo
                      :disabled="item.status != 'Submitted'"
                      @change="changeReviewStatus(item)"
                    ></v-select>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12">
          <v-card-title class="px-0">
            <h2 class="text-h3">Additional Resources</h2>
            <v-spacer></v-spacer>
          </v-card-title>
          <div class="d-flex justify-start flex-wrap">
            <v-card
              class="pa-2 resource-card mb-5 mr-4"
              outlined
              max-width="265px"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 header-card">
                    <b>Safe Work Procedure</b>
                  </div>
                  <v-list-item-title class="headline mb-1 title-card">
                    Cleaning: Workstation
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-card"
                    >A procedure to follow for cleaning various
                    workstations.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="https://portal.mycoraapp.com/media/workstation_cleaning_procedures_29_Jan_21.pdf"
                >
                  <v-icon left>mdi-download</v-icon>
                </a>
              </v-card-actions>
            </v-card>
            <v-card
              class="pa-2 resource-card mb-5 mr-4"
              outlined
              max-width="265px"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4 header-card">
                    <b>Safe Work Procedure</b>
                  </div>
                  <v-list-item-title class="headline mb-1 title-card">
                    Cleaning: Equipment
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-card"
                    >A procedure to follow for cleaning items and
                    equipment.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <a
                  target="_blank"
                  style="text-decoration: none"
                  href="http://portal.mycoraapp.com/media/shared_cleaning_procedures_29Jan_21.pdf"
                >
                  <v-icon left>mdi-download</v-icon>
                </a>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row> -->
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>

    <!-- <v-dialog persistent v-model="consolidateReportsModal" max-width="750">
      <v-card>
        <v-form ref="form" v-model="validConsolidateForm" lazy-validation>
          <v-container>
            <v-card-title class="headline">
              Consolidate reports
            </v-card-title>

            <v-card-text class="pb-0"
              >You need to put a name for the new Consolidate</v-card-text
            >

            <v-card-text>
              <v-text-field
                class="pt-0"
                :rules="valueRules"
                v-model="labelConsolidateReport"
                hide-details="auto"
                required
                label="Type name here"
              ></v-text-field>
            </v-card-text>

            <v-card-text
              >You have requested to consolidate the following 2
              reports:</v-card-text
            >
            <v-data-table
              :headers="headersModal"
              :items="selectedReports"
              multi-sort
              :hide-default-footer="true"
              class="elevation-5 align-center"
            >
              <template v-slot:item.risk="{ item }">
                <v-chip :color="getColor(item.final_risk_level)" dark>{{
                  item.final_risk_level
                }}</v-chip>
              </template>
            </v-data-table>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="green darken-1"
              text
              @click="consolidateReports()"
            >
              Confirm
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="
                consolidateReportsModal = false;
                labelConsolidateReport = '';
              "
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="deleteReportModal" persistent max-width="310">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this report?
        </v-card-title>
        <v-card-text>It can't be recovered.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteReport(deleteReportId)"
          >
            Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteReportModal = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="deleteConsolidateReportModal" persistent max-width="310">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this report?
        </v-card-title>
        <v-card-text>It can't be recovered.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteConsolidateReport(deleteConsolidateReportId)"
          >
            Yes
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteConsolidateReportModal = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="errorMergeModal" persistent max-width="310">
      <v-card>
        <v-card-title class="headline">
          Error trying to merge.
        </v-card-title>
        <v-card-text>
          <span style="text-transform: capitalize">{{
            this.errorConsolidate
          }}</span
          >.

          <br />Please check that all the conditions are met</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="errorMergeModal = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showReviewStatusSnach" right top>
      Review Status changed
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showReviewStatusSnach = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import ReportService from "@/services/report.service";
import LicenseService from "@/services/license.service";
import moment from "moment";

export default {
  name: "Generic",
  components: {
    Header
  },
  data() {
    return {
      showReviewStatusSnach: false,
      reviewStatusSelected: null,
      errorMergeModal: false,
      errorLabel: false,
      labelConsolidateReport: "",
      valueRules: [
        value => !!value || "Required.",
        value => (value && value.length >= 3) || "Min 3 characters"
      ],
      validConsolidateForm: true,
      selectedReports: [],
      isLoading: false,
      deleteReportModal: false,
      deleteReportId: null,
      deleteConsolidateReportModal: false,
      deleteConsolidateReportId: null,
      dialog: false,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      dataConsolidateLoading: true,
      options: {},
      options_cons: {},
      itemsConsolidatedTotal: 0,
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      search: "",
      consReports: [],
      orgReports: [],
      userReport: [],
      expanded: [],
      errorConsolidate: "",
      singleExpand: true,
      statusList: ["Newly Submitted", "Approved", "Rejected", "Under Review"],
      headers_consolidate: [
        { text: "Report", value: "id_label" },
        { text: "Date submitted", value: "created_at" },
        // { text: "Worker group type", value: "wgtype.name" },
        // { text: "Worker group", value: "workergroup.name" },
        // { text: "Submitted by", value: "user.name" },
        { text: "Risk level", value: "risk" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false
        }
      ],

      headers: [
        { text: "Report", value: "id_title" },
        { text: "Date submitted", value: "created_at" },
        { text: "Location", value: "location.name" },
        { text: "Worker group", value: "workergroup.name" },
        { text: "Risk level", value: "risk" },
        { text: "Status", value: "status" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],

      headersModal: [
        { text: "ID", value: "id" },
        { text: "Date submitted", value: "created_at" },
        { text: "Submitted by", value: "user.name" },
        { text: "Risk level", value: "risk" }
      ],
      orgReportsModal: [
        {
          created_at: "2020-12-18 3:02 pm",
          id: 605,
          risk: "High",
          user: {
            name: "Demo Admin"
          }
        },
        {
          created_at: "2020-12-18 3:02 pm",
          id: 625,
          risk: "Medium",
          user: {
            name: "Demo Admin"
          }
        }
      ],
      consolidateReportsModal: false,
      editedIndex: -1,
      approvedReports: 0,
      rejectedReports: 0,
      pendingReports: 0
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    options_cons: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getConsReports();
        }
      },
      deep: true
    },

    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedReports();
        }
      },
      deep: true
    }
  },

  computed: {
    showConsolidateButton() {
      if (this.selectedReports.length && this.selectedReports.length > 1) {
        let statusFlag = this.selectedReports.find(
          e => e.status !== "Approved"
        );
        if (statusFlag) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  methods: {
    async changeReviewStatus(report) {
      this.isLoading = true;
      const reportReviewStatus = {
        status: report.status,
        is_complete: 1,
        active_user_id: null
      };
      await ReportService.update({
        id: report.id,
        data: reportReviewStatus
      });
      this.reviewStatusSelected = null;
      this.isLoading = false;
      this.showReviewStatusSnach = true;
    },

    getNestedObject(nestedObj, pathArr) {
      //Access Nested Objects Using Array Reduce // hackernoon
      return pathArr.reduce(
        (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
        nestedObj
      );
    },

    async consolidateReports() {
      this.errorConsolidate = "";
      if (this.$refs.form.validate()) {
        this.consolidateReportsModal = false;
        this.isLoading = true;
        try {
          await ReportService.postConsolidateReport({
            query: {
              label: this.labelConsolidateReport,
              report_ids: this.selectedReports
            }
          });
        } catch (e) {
          this.errorConsolidate = e.response.data.message;
          this.errorMergeModal = true;
        }
        await this.getConsReports();
        this.labelConsolidateReport = "";
        this.selectedReports.length = 0;
        this.isLoading = false;
      }
    },

    modalConsolidateReports() {
      this.consolidateReportsModal = true;
    },

    fireModalDeleteReport(id) {
      this.deleteReportId = id;
      this.deleteReportModal = true;
    },

    async deleteReport() {
      this.isLoading = true;
      this.deleteReportModal = false;
      await ReportService.remove({ id: this.deleteReportId });
      this.isLoading = false;
    },

    consolidateFireModalDeleteReport(id) {
      this.deleteConsolidateReportId = id;
      this.deleteConsolidateReportModal = true;
    },

    async deleteConsolidateReport() {
      this.isLoading = true;
      this.dataConsolidateLoading = true;
      this.deleteConsolidateReportModal = false;
      await ReportService.removeConsolidate({
        id: this.deleteConsolidateReportId
      });
      this.isLoading = false;
      this.dataConsolidateLoading = false;
    },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getMyLicenses();
      this.getPagedReports();
      this.getConsReports();
    },

    async getMyLicenses() {
      await LicenseService.getMyLicenses({});
    },

    async getConsReports() {
      this.dataConsolidateLoading = true;

      const orgId = this.getOrgId();
      const { page } = this.options_cons;
      const response = await ReportService.getConsolidateReport({
        id: orgId,
        query: {
          page: page
        }
      });

      this.options_cons.itemsPerPage = parseInt(response.data.meta.per_page);
      this.itemsConsolidatedTotal = response.data.meta.total;
      this.consReports = response.data.data.slice();
      this.dataConsolidateLoading = false;
    },

    async getPagedReports() {
      this.dataLoading = true;

      const orgId = this.getOrgId();
      const { page } = this.options;

      const response = await ReportService.get({
        id: orgId,
        query: {
          page: page
        }
      });

      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.orgReports = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      // // sorting disabled
      // if (sortBy.length === 1 && sortDesc.length === 1) {
      //   this.orgReports = this.orgReports.sort((a, b) => {
      //     const sortA = a[sortBy[0]]
      //     const sortB = b[sortBy[0]]

      //     if (sortDesc[0]) {
      //       if (sortA < sortB) return 1
      //       if (sortA > sortB) return -1
      //       return 0
      //     } else {
      //       if (sortA < sortB) return -1
      //       if (sortA > sortB) return 1
      //       return 0
      //     }
      //   })
      // }

      // if (itemsPerPage > 0) {
      //   this.orgReports = this.orgReports.slice(
      //     (this.currentPage - 1) * itemsPerPage,
      //     this.currentPage * itemsPerPage
      //   );
      // }

      this.formatReportDate();
      this.checkOnLiveFix();
      this.dataLoading = false;
    },

    async checkOnLiveFix() {
      let refresh = false;
      await this.orgReports.forEach(rep => {
        if (rep.active_user_id == this.currentUser.user.id) {
          ReportService.update({
            id: rep.id,
            data: {
              active_user_id: null
            }
          });
          refresh = true;
        }
      });
      if (refresh) {
        this.getPagedReports();
      }
    },

    async formatReportDate() {
      await this.consReports.map(rep => {
        return (rep.created_at = moment(rep.created_at).format("YYYY-MM-DD"));
      });

      await this.orgReports.map(rep => {
        return (rep.created_at = moment(rep.created_at).format("YYYY-MM-DD"));
      });
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    // deleteItem(item) {
    //   this.editedIndex = this.orgReports.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // async confirmDelete(item) {
    //   const index = this.orgReports.indexOf(item);
    //  this.orgReports.splice(index, 1);
    //   await ReportService.remove({
    //     id: item.id
    //   });
    //   console.log('item.id', item.id)
    //   this.closeDelete();
    //   this.itemsTotal--;
    //   this.showSnackbar("Report #" + item.id + " has been deleted");
    // },

    editItem(item) {
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.orgReports.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          const updatedItem = Object.assign({}, item);
          this.orgReports[this.editedIndex].status = updatedItem.status;

          /// shitty way to do it, should be refactored into its own method
          if (updatedItem.status === "Approved") {
            this.approvedReports++;
          } else if (updatedItem.status === "Under Review") {
            this.pendingReports++;
          } else if (updatedItem.status === "Rejected") {
            this.rejectedReports++;
          }

          if (this.originalItem.status === "Approved") {
            this.approvedReports--;
          } else if (this.originalItem.status === "Under Review") {
            this.pendingReports--;
          } else if (this.originalItem.status === "Rejected") {
            this.rejectedReports--;
          }

          this.showSnackbar("Status has been updated");
        }
      }

      this.close();
    },

    customDataTableItemsFilter(value, search, items) {
      /*
      Filter for individual words in search string. Filters
      all object values rather than just the keys included
      in the data table headers.
       */
      const wordArray = search
        .toString()
        .toLowerCase()
        .split(" ")
        .filter(x => x);
      return wordArray.every(word =>
        JSON.stringify(Object.values(items))
          .toString()
          .toLowerCase()
          .includes(word)
      );
    },
    getColor(risk) {
      if (risk == "High") return "error";
      else if (risk == "Moderate") return "warning";
      else return "success";
    },
    getWorkerGroupByReportId(reportId) {
      const report = this.reports.filter(report => report.id === reportId);
      return report[0].workerGroups;
    }
  }
};
</script>
<style lang="scss" scoped>
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
.resource-card {
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
