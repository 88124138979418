var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-org innerpage"},[_c('Header'),(!_vm.isLoading)?_c('v-container',{staticClass:"my-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.currentUser)?_c('div',{staticClass:"d-lg-flex align-center"},[_c('h1',{staticClass:"text-h2 mr-3"},[_vm._v("Org admin dashboard")]),_c('v-chip',{staticClass:"ml-2 mt-2 mb-2",attrs:{"color":"cora-chip-color-green","text-color":"white"}},[_c('v-avatar',{staticClass:"cora-chip-avatar-color-green darken-4",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.itemsTotal)+" ")]),_vm._v(" Submitted reports ")],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-8 mb-3"})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"px-0"},[_c('v-container',{staticClass:"fluid d-lg-flex align-center"},[_c('h2',{staticClass:"text-h3 mr-auto"},[_vm._v(" "+_vm._s(_vm.itemsTotal)+" Reports submitted ")])]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-5 mb-15 align-center",attrs:{"loading":_vm.dataLoading,"loading-text":"Loading... Please wait","item-key":"id","disable-sort":true,"headers":_vm.headers,"items":_vm.orgReports,"options":_vm.options,"server-items-length":_vm.itemsTotal,"sort-desc":[false, true],"multi-sort":"","show-expand":"","show-select":"","search":_vm.search,"custom-filter":_vm.customDataTableItemsFilter,"footer-props":{
            itemsPerPageOptions: [10]
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.risk",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.final_risk_level),"dark":""}},[_vm._v("Low")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(
                item.status != 'In Progress' &&
                  item.status != 'started' &&
                  item.status != 'Incomplete' &&
                  item.active_user_id == null
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"icon-link",attrs:{"href":("/report/" + (item.id))}},'a',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-playlist-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit report")])]):_vm._e(),(
                item.status != 'In Progress' &&
                  item.status != 'started' &&
                  item.status != 'Incomplete' &&
                  item.active_user_id == null
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"icon-link",attrs:{"href":("/quizreport/" + (item.id)),"target":"_blank"}},'a',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("View report")])]):_vm._e(),(item.active_user_id == null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.fireModalDeleteReport(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete report")])]):_vm._e(),(item.active_user_id != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#ff0000"}},'v-icon',attrs,false),on),[_vm._v(" mdi-access-point")])]}}],null,true)},[_c('span',[_vm._v("On live editing")])]):_vm._e()]}},{key:"item.id_title",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" - "+_vm._s(_vm.getNestedObject(item, ["courseDetails", 0, "category"]))+" ")]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0 ",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"fix-detail-dashboard  rounded-0"},[_c('v-card-text',[_c('p',[_c('b',[_vm._v("Report ID:")]),_vm._v(" "+_vm._s(item.id))]),_c('p',[_c('b',[_vm._v("Report name:")]),_vm._v(" "+_vm._s(_vm.getNestedObject(item, ["courseDetails", 0, "title"]))+" ")]),_c('p',[_c('b',[_vm._v("Report category:")]),_vm._v(" "+_vm._s(_vm.getNestedObject(item, ["courseDetails", 0, "category"]))+" ")]),_c('p',[_c('b',[_vm._v("Submitted by:")]),_vm._v(" "+_vm._s(item.user.name))]),_c('p',[_c('b',[_vm._v("Review status:")]),_vm._v(" (you can't change this if the report is not in \"Submitted\" status ) ")]),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":['Approved', 'Rejected', 'Reviewing'],"label":"No status","dense":"","solo":"","disabled":item.status != 'Submitted'},on:{"change":function($event){return _vm.changeReviewStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)],1)],1)]}}],null,false,3868828738),model:{value:(_vm.selectedReports),callback:function ($$v) {_vm.selectedReports=$$v},expression:"selectedReports"}}),_c('v-snackbar',{attrs:{"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}],null,false,508673173),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")])],1)],1)],1):[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center text-overline mb-8"},[_vm._v("Loading new data")]),_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":"","height":"6"}})],1)],_c('v-dialog',{attrs:{"persistent":"","max-width":"310"},model:{value:(_vm.deleteReportModal),callback:function ($$v) {_vm.deleteReportModal=$$v},expression:"deleteReportModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete this report? ")]),_c('v-card-text',[_vm._v("It can't be recovered.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteReport(_vm.deleteReportId)}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteReportModal = false}}},[_vm._v(" No ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"310"},model:{value:(_vm.errorMergeModal),callback:function ($$v) {_vm.errorMergeModal=$$v},expression:"errorMergeModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Error trying to merge. ")]),_c('v-card-text',[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(this.errorConsolidate))]),_vm._v(". "),_c('br'),_vm._v("Please check that all the conditions are met")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.errorMergeModal = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-snackbar',{attrs:{"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showReviewStatusSnach = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.showReviewStatusSnach),callback:function ($$v) {_vm.showReviewStatusSnach=$$v},expression:"showReviewStatusSnach"}},[_vm._v(" Review Status changed ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }